import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <GridList columns="2" mdxType="GridList">
  <GridListItem title="Buttons and links" titleElement="h3" linkText="Go to buttons and links" linkTo="/copy/writing-in-experiences/buttons-and-links" border mdxType="GridListItem">
    How to write buttons and links in your experiences.
  </GridListItem>
  <GridListItem title="Error messages" titleElement="h3" linkText="Go to error messages" linkTo="/copy/writing-in-experiences/error-messages" border icon="error-messages" mdxType="GridListItem">
    How to write error messages in your experiences.
  </GridListItem>
  <GridListItem title="Numbers, dates and time" titleElement="h3" linkText="Go to numbers, dates and time" linkTo="/copy/writing-in-experiences/numbers,-dates-and-time" border mdxType="GridListItem">
    How to write numbers, dates and time in your experiences.
  </GridListItem>
  <GridListItem title="Onboarding messages" titleElement="h3" linkText="Go to onboarding messages" linkTo="/copy/writing-in-experiences/onboarding-messages" border mdxType="GridListItem">
    How to write onboarding messages in your experiences.
  </GridListItem>
  <GridListItem title="Success messages" titleElement="h3" linkText="Go to success messages" linkTo="/copy/writing-in-experiences/success-messages" border mdxType="GridListItem">
    How to write success messages in your experiences.
  </GridListItem>
    </GridList>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      